import React from "react";

const Tech2 = ({ color }) => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10552_35239)">
        <path
          d="M8.3282 4.6589C8.12714 4.6589 7.92607 4.72246 7.76734 4.82839L6.53979 3.58898C6.64562 3.43008 6.70911 3.23941 6.70911 3.02754C6.70911 2.4661 6.25407 2 5.68263 2C5.11119 2 4.65615 2.45551 4.65615 3.02754C4.65615 3.22881 4.71964 3.43008 4.82547 3.58898L3.58734 4.8178C3.42861 4.71186 3.23813 4.6483 3.02648 4.6483C2.46562 4.6483 2 5.10381 2 5.67585C2 6.24788 2.45504 6.70339 3.02648 6.70339C3.59792 6.70339 4.05296 6.24788 4.05296 5.67585C4.05296 5.47458 3.98947 5.2733 3.88364 5.11441L5.11119 3.88559C5.26992 3.99153 5.4604 4.05508 5.67205 4.05508C5.87311 4.05508 6.07417 3.99153 6.23291 3.88559L7.46045 5.11441C7.35463 5.2733 7.29114 5.46398 7.29114 5.67585C7.29114 6.23729 7.74617 6.70339 8.31762 6.70339C8.87848 6.70339 9.3441 6.24788 9.3441 5.67585C9.3441 5.10381 8.88906 4.6589 8.3282 4.6589Z"
          fill="black"
        />
        <path
          d="M10.9729 7.30747C10.412 7.30747 9.94641 7.76298 9.94641 8.33501C9.94641 8.53628 10.0099 8.73755 10.1157 8.89645L8.8776 10.1147C8.71887 10.0087 8.52839 9.94518 8.31674 9.94518C8.11568 9.94518 7.91461 10.0087 7.75588 10.1147L6.53892 8.88586C6.64474 8.72696 6.70823 8.53628 6.70823 8.32442C6.70823 7.76298 6.2532 7.29688 5.68175 7.29688C5.11031 7.29688 4.65527 7.75238 4.65527 8.32442C4.65527 8.88586 5.11031 9.35196 5.68175 9.35196C5.88282 9.35196 6.08388 9.2884 6.24261 9.18247L7.47016 10.4113C7.36434 10.5702 7.30084 10.7609 7.30084 10.9727C7.30084 11.5342 7.75588 12.0003 8.32732 12.0003C8.88818 12.0003 9.3538 11.5448 9.3538 10.9727C9.3538 10.7715 9.29031 10.5702 9.18449 10.4113L10.412 9.18247C10.5708 9.2884 10.7612 9.35196 10.9729 9.35196C11.5338 9.35196 11.9994 8.89645 11.9994 8.32442C11.9888 7.76298 11.5338 7.30747 10.9729 7.30747Z"
          fill="black"
        />
        <path
          d="M5.68263 9.95564C5.48157 9.95564 5.2805 10.0192 5.12177 10.1251L3.89423 8.88573C4.00005 8.72683 4.06354 8.53615 4.06354 8.32428C4.06354 8.12301 4.00005 7.92174 3.89423 7.76284L5.12177 6.53403C5.2805 6.63996 5.47099 6.70352 5.68263 6.70352C6.24349 6.70352 6.70911 6.24801 6.70911 5.67598C6.70911 5.10395 6.25407 4.64844 5.68263 4.64844C5.11119 4.64844 4.65615 5.10395 4.65615 5.67598C4.65615 5.87725 4.71964 6.07852 4.82547 6.23742L3.58734 7.46623C3.42861 7.3603 3.23813 7.29674 3.02648 7.29674C2.46562 7.29674 2 7.75225 2 8.32428C2 8.88573 2.45504 9.35183 3.02648 9.35183C3.22754 9.35183 3.42861 9.28827 3.58734 9.18233L4.81488 10.4111C4.70906 10.57 4.64557 10.7607 4.64557 10.9726C4.64557 11.534 5.10061 12.0001 5.67205 12.0001C6.24349 12.0001 6.69853 11.5446 6.69853 10.9726C6.69853 10.4111 6.24349 9.95564 5.68263 9.95564Z"
          fill="black"
        />
        <path
          d="M10.9736 4.6589C10.7726 4.6589 10.5715 4.72246 10.4128 4.82839L9.18524 3.58898C9.29107 3.43008 9.35456 3.23941 9.35456 3.02754C9.35456 2.4661 8.89952 2 8.32808 2C7.76722 2 7.3016 2.45551 7.3016 3.02754C7.3016 3.59958 7.75664 4.05508 8.32808 4.05508C8.52914 4.05508 8.7302 3.99153 8.88894 3.88559L10.1165 5.11441C10.0107 5.2733 9.94717 5.46398 9.94717 5.67585C9.94717 5.87712 10.0107 6.07839 10.1165 6.23729L8.87836 7.4661C8.71962 7.36017 8.52914 7.29661 8.3175 7.29661C7.75664 7.29661 7.29102 7.75212 7.29102 8.32415C7.29102 8.88559 7.74605 9.35169 8.3175 9.35169C8.87836 9.35169 9.34398 8.89619 9.34398 8.32415C9.34398 8.12288 9.28048 7.92161 9.17466 7.76271L10.4022 6.5339C10.5609 6.63983 10.7514 6.70339 10.9631 6.70339C11.5239 6.70339 11.9895 6.24788 11.9895 5.67585C11.9895 5.10381 11.5345 4.6589 10.9736 4.6589Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_10552_35239">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Tech2;
